<template>
  <modal
    :name="modalName"
    height="auto"
    width="540px"
    @before-open="onBeforeOpen"
  >
    <div class="modal-body">
      <close-button
        @click="$modal.hide(modalName)"
      />

      <x-icon
        name="check"
        class="modal-body__icon"
      />

      <heading level="2">
        <slot>
          Приглашение отправлено на {{ email }}
        </slot>
      </heading>

      <x-btn
        :to="back"
      >
        Вернуться
      </x-btn>
    </div>
  </modal>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import CloseButton from '@/components/Interface/CloseButton.vue'

export default {
  components: {
    Heading,
    CloseButton
  },

  data () {
    return {
      modalName: 'success-create-modal',
      email: '',
      back: {}
    }
  },

  methods: {
    onBeforeOpen (e) {
      const { email, back } = e.params
      this.email = email
      this.back = back
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    padding $space * 8
    text-align center

    &__icon
      width 72px
      height 72px
      color $colors.success
</style>
