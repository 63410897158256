<template>
  <seller-form
    class="sp-form"
    @submit="onSubmit"
  >
    <fieldset class="form-fields-group">
      <ul class="form-fields-list">
        <li class="form-fields-list__item">
          <x-form-item
            v-slot="validationData"
            name="Название ИП"
            label="Название ИП"
            rules="required"
          >
            <x-input
              id="legal_name"
              :value="value.legal_name"
              v-bind="validationData"
              @input="onInput($event, 'legal_name')"
            />
          </x-form-item>
        </li>

        <li class="form-fields-list__item">
          <x-form-item
            v-slot="validationData"
            name="Адрес регистрации"
            label="Адрес регистрации"
            rules="required"
          >
            <x-input
              id="legal_address"
              :value="value.legal_address"
              v-bind="validationData"
              @input="onInput($event, 'legal_address')"
            />
          </x-form-item>
        </li>

        <li class="form-fields-list__item">
          <x-form-item
            v-slot="validationData"
            name="ИНН"
            label="ИНН"
            rules="required|digits:12"
          >
            <x-input
              id="inn"
              :value="value.inn"
              v-bind="validationData"
              @input="onInput($event, 'inn')"
            />
          </x-form-item>
        </li>

        <li class="form-fields-list__item">
          <x-form-item
            v-slot="validationData"
            name="БИК"
            label="БИК"
            rules="required|digits:9"
          >
            <x-input
              id="bic"
              :value="value.bic"
              v-bind="validationData"
              @input="onInput($event, 'bic')"
            />
          </x-form-item>
        </li>

        <li class="form-fields-list__item">
          <x-form-item
            v-slot="validationData"
            name="Р/С"
            label="Р/С"
            rules="required|digits:20"
          >
            <x-input
              id="payment_account"
              :value="value.payment_account"
              v-bind="validationData"
              @input="onInput($event, 'payment_account')"
            />
          </x-form-item>
        </li>
      </ul>

      <button
        class="visually-hidden"
        type="submit"
      />
    </fieldset>
  </seller-form>
</template>

<script>
import SellerForm from '@/components/Interface/Form.vue'

export default {
  components: {
    SellerForm
  },

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    onInput (value, field) {
      this.$emit('input', { value, field })
    },

    onSubmit () {
      this.$emit('submit')
    }
  }
}
</script>
