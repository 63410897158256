<template>
  <validation-observer v-slot="{ handleSubmit }">
    <seller-form
      class="client"
      @submit="handleSubmit(onSubmit)"
    >
      <x-form-item
        v-slot="validationData"
        name="Название клиента"
        label="Название клиента"
        rules="required"
      >
        <x-input
          id="client-name"
          v-model="form.name"
          placeholder="Введите название"
          size="s"
          v-bind="validationData"
        />
      </x-form-item>

      <radio-buttons-group
        horizontal
        :items="clientEntityTypes"
        :value="form.legal_entity_type"
        class="client__type"
        @change="onClientEntityChange"
      />

      <x-divider
        v-if="currentFormComponent"
        class="client__divider"
      />

      <component
        :is="currentFormComponent"
        :value="form"
        @input="onFormInput"
        @submit="handleSubmit(onSubmit)"
      />

      <div
        v-if="currentFormComponent"
        class="client__actions"
      >
        <x-btn
          color="gray"
          :to="backRoute"
        >
          Отменить
        </x-btn>

        <x-btn
          type="submit"
          :loading="isLoading"
        >
          Сохранить
        </x-btn>
      </div>

      <success-create-modal>
        «{{ form.name }}» добавлен
      </success-create-modal>
    </seller-form>
  </validation-observer>
</template>

<script>
import SellerForm from '@/components/Interface/Form.vue'
import RadioButtonsGroup from '@/components/Interface/RadioButtonsGroup.vue'
import SuccessCreateModal from '@/components/Modals/SuccessCreateModal.vue'
import * as ClientEntityForms from '@/components/Settings/Permissions/Forms/Client'
import { clientEntityTypes } from '@/constants/settings'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('settings/permissions')

export default {
  components: {
    ...ClientEntityForms,
    SuccessCreateModal,
    RadioButtonsGroup,
    SellerForm
  },

  data () {
    return {
      clientEntityTypes,
      form: {
        name: '',
        phone: null,
        email: null,
        legal_entity_type: null,
        legal_name: null,
        legal_address: null,
        inn: null,
        kpp: null,
        bic: null,
        payment_account: null
      },
      isLoading: false
    }
  },

  computed: {
    clientId () {
      return this.$route.params.id
    },

    isEdit () {
      return !!this.clientId
    },

    backRoute () {
      return this.$route.meta.back
    },

    clientEntityForms () {
      return {
        OOO: 'ltd',
        IP: 'sole-proprietor',
        individual: 'individual'
      }
    },

    currentFormComponent () {
      return this.clientEntityForms[this.form.legal_entity_type]
    }
  },

  async created () {
    if (!this.clientId) return
    const client = await this.getClient(this.clientId)
    this.form = client
    this.$route.meta.heading = client.name
  },

  methods: {
    ...mapActions([
      'getClient',
      'updateClient',
      'createClient'
    ]),

    onFormInput ({ field, value }) {
      this.form[field] = value
    },

    onClientEntityChange (value) {
      this.form.legal_entity_type = value
    },

    async onSubmit () {
      try {
        this.isLoading = true

        if (this.isEdit) {
          await this.updateClient(this.form)
          this.$toast.success('Данные сохранены')
          this.$router.push(this.$route.meta.back)
          return
        }

        await this.createClient(this.form)
        this.$modal.show('success-create-modal', {
          back: this.$route.meta.back
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .client
    display block
    width 430px

    &__divider
      margin 20px 0

    &__type
      margin-top 12px

    &__actions
      display flex
      justify-content flex-end
      margin-top 20px
</style>
