<template>
  <fieldset class="requisites form-fields-group">
    <ul class="form-fields-list">
      <li class="form-fields-list__item">
        <x-form-item
          v-slot="validationData"
          label="ФИО"
          name="ФИО"
          rules="required"
        >
          <x-input
            id="name"
            :value="value.legal_name"
            placeholder="Введите ФИО"
            v-bind="validationData"
            @input="onInput($event, 'legal_name')"
          />
        </x-form-item>
      </li>

      <li class="form-fields-list__item">
        <x-form-item
          v-slot="validationData"
          label="Телефон"
          name="Телефон"
          rules="required|digits-length:11"
        >
          <x-input
            id="phone"
            v-mask="'+7 (###) ###-##-##'"
            :value="value.phone"
            placeholder="Введите телефон"
            v-bind="validationData"
            @input="onInput($event, 'phone')"
          />
        </x-form-item>
      </li>

      <li class="form-fields-list__item">
        <x-form-item
          v-slot="validationData"
          label="ИНН"
          name="ИНН"
          rules="required|digits:12"
        >
          <x-input
            id="inn"
            :value="value.inn"
            placeholder="Введите ИНН"
            v-bind="validationData"
            @input="onInput($event, 'inn')"
          />
        </x-form-item>
      </li>
    </ul>
  </fieldset>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    onInput (value, field) {
      this.$emit('input', { value, field })
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
